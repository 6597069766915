import {
    IAppCompany,
    ICompaniesRecruit,
    ILocalesRecruit,
    IRecuitInfor,
} from '../../types/recruit.type';
import { Observable } from 'rxjs';
import { AppsManagementResponse, CMSResponse } from '../../types/common.type';
import { HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';

export const listRecruit: IRecuitInfor[] = [];

export interface Career {
    id: number;
    attributes: {
        Title: string;
        CustomLink: string;
        Description: string;
        Requirement: string;
        Benefit: string;
        Amount: number;
        MinimumYearsOfExperience: number;
        Salary: string;
        WorkingTime: string;
        Limitation: string;
        Quote: string;
        Label: string;
        Tags: string;
        Type: string;
        Location: string;
        Level: string;
        ContactInfos: {
            data: any[];
        };
        Poster: {
            data: File;
        };
        locale?: string;
        localizations?: any;
        createdAt: string;
        updatedAt: string;
        publishedAt?: string;
    };
}
export interface TitleInCareer {
    id: number;
    attributes: {
        TitleSalary: string;
        TitleLimitation: string;
        TitleButtonHome: string;
        TitleHome: string;
        TitleDescription: string;
        TileRequirement: string;
        TitleBenefit: string;
        TitleAmount: string;
        TitleMinimumYearsOfExperience: string;
        TitleWorkingTime: string;
        TitlecreatedAt: string;
        TitleSubmit: string;
        TitleInformationDialog: string;
        TitleButtonViewDetail: string;
        createdAt: string;
        updatedAt: string;
        publishedAt: string;
        locale: string;
        title_in_forms: {
            data: [];
        };
    };
}

export interface CareerPage {
    id: number;
    attributes: {
        Banner: {
            data: File;
        };
        BannerSlide?: {
            data: File;
        };
        BannerURL: string;
        Career: {
            data: Career[];
        };
        Description: string;
        SecondBanner?: {
            data: File;
        };
        SecondBannerSlide?: {
            data: File;
        };
        SecondBannerURL: string;
        TextButtonSearch: string;
        TextField: string;
        TextKeyWord: string;
        TextNoData: string;
        Title: string;
        locale: string;
        createdAt: string;
        publishedAt: string;
        updatedAt: string;
        localizations?: any;
        title_in_careers: {
            data: TitleInCareer[];
        };
    };
    slug?: string;
    published?: boolean;
    is_hot?: boolean;
    amount?: number;
    specialize_id: string;
    company_id: string;
    experience?: number;
    level?: string;
    working_time: string;
    start_date: string;
    end_date: string;
    created_time?: number | string;
    updated_time?: number | string;
    name: string;
    description: string;
    salary: string;
    unit: string;
    created_by?: null;
    locales: ILocalesRecruit[];
    company: ICompaniesRecruit;
    formality?: string;
}

export abstract class RecruitData {
    abstract GetCareerPage(
        props: any
    ): Observable<AppsManagementResponse<CareerPage[]>>;
    abstract GetDetailCareer(slug: string): Observable<CMSResponse<CareerPage>>;
    abstract GetCompanies(): Observable<CMSResponse<ICompaniesRecruit[]>>;
    abstract GetAppCompanies(
        slug: string
    ): Observable<CMSResponse<IAppCompany[]>>;
}

export const RefTypesVi: { name: string; type: string }[] = [
    {
        name: 'Facebook',
        type: 'FACEBOOK',
    },
    {
        name: 'LinkedIn',
        type: 'LINKEDLN',
    },
    {
        name: 'Website',
        type: 'WEBSITE',
    },
    {
        name: 'Nội bộ Volio Group',
        type: 'INTERNAL_VOLIO',
    },
    {
        name: 'Nguồn khác',
        type: 'OTHER',
    },
];

export const RefTypesEn: { name: string; type: string }[] = [
    {
        name: 'Facebook',
        type: 'FACEBOOK',
    },
    {
        name: 'LinkedIn',
        type: 'LINKEDLN',
    },
    {
        name: 'Website',
        type: 'WEBSITE',
    },
    {
        name: 'Internal Volio Group',
        type: 'INTERNAL_VOLIO',
    },
    {
        name: 'Other',
        type: 'OTHER',
    },
];
